import React from 'react';
import './Footer.css'; // Ensure your CSS is imported for styling

function Footer() {
  return (
    <footer className='footer-container'>
      <section className='footer-contact'>
        <h2 className='footer-heading'>Kontakt</h2>
        
        <div className='footer-contact-details'>
          <p>
            <i className="fas fa-phone"></i>
            +49 175 487 50 51
          </p>
          <p>
            <i className="fas fa-phone"></i>
             +386 41 374 110
          </p>
          <p>
            <i className="fas fa-envelope"></i>
            <span>wolf(at)mud-deutschland.de</span>
          </p>
        </div>

        <div className='footer-location'>
          <h3 className='footer-subheading'>Unser Standort</h3>
          <address>
            MUD Deutschland GmbH <br />
            Am Grossen Dahlen 33 <br />
            D-21077 Hamburg, Deutschland
          </address>
        </div>
      </section>
    </footer>
  );
}

export default Footer;