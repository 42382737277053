import React from 'react';
import './UberUns.css';
import { motion } from 'framer-motion';

function UberUns() {
  return (
    <motion.div
      className='uber-uns-container'
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, staggerChildren: 0.2 }}
      viewport={{ once: true }}
    >
      <motion.h1
        className='uber-uns-heading'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{ textAlign: 'center' }}
      >
        Erfahren Sie mehr über uns
      </motion.h1>

      <motion.p
        className='uber-uns-intro-text'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.7 }}
        style={{ textAlign: 'center', marginTop: '1rem' }}
      >
        Wir sind seit über 10 Jahren in der Projektsteuerung und -leitung tätig und haben uns auf die Beratung und kaufmännische Betreuung von Projekten im Bereich Bauschlosserei und Glasbau im Wohnungsbau spezialisiert. Unsere Expertise umfasst unter anderem Pfosten-Riegel-Konstruktionen und Schüco-Fenster. Hauptsächlich sind wir in Berlin und Norddeutschland aktiv.
      </motion.p>
      
      <motion.div
        className='uber-uns-section'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>Dienstleistungen</h2>
        <div className='uber-uns-services' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
          <div className='service-wrapper' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: '2rem', maxWidth: '1200px' }}>
            <motion.div className='service'
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              style={{ flex: '1 1 45%', minWidth: '280px' }}
            >
              <h3 style={{ textAlign: 'left' }}>Projektsteuerung</h3>
              <p>
                Wir bieten umfassende Dienstleistungen in der Projektsteuerung an, die eine effiziente Planung,
                Durchführung und Kontrolle von Bauprojekten sicherstellen.
              </p>
            </motion.div>
            
            <motion.div className='service'
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              style={{ flex: '1 1 45%', minWidth: '280px' }}
            >
              <h3 style={{ textAlign: 'left' }}>Beratung</h3>
              <p>
                Unsere erfahrenen Berater unterstützen Sie in allen kaufmännischen Belangen und helfen Ihnen,
                Ihre Projekte erfolgreich umzusetzen.
              </p>
            </motion.div>
          </div>
          <div className='service-wrapper' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: '2rem', maxWidth: '1200px', marginTop: '2rem' }}>
            <motion.div className='service'
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
              style={{ flex: '1 1 45%', minWidth: '280px' }}
            >
              <h3 style={{ textAlign: 'left' }}>Spezialisierung auf Bauschlosserei</h3>
              <p>
                Wir verfügen über umfangreiche Erfahrung in der Betreuung von Projekten im Bereich Bauschlosserei,
                insbesondere im Wohnungsbau.
              </p>
            </motion.div>
            
            <motion.div className='service'
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              style={{ flex: '1 1 45%', minWidth: '280px' }}
            >
              <h3 style={{ textAlign: 'left' }}>Glasgeländer und Bürotrennwände</h3>
              <p>
                Seit über 10 Jahren sind wir europaweit im Bereich Glasgeländer (innen und außen) sowie Bürotrennwände tätig.
              </p>
            </motion.div>
          </div>
        </div>
      </motion.div>

      <motion.div
        className='uber-uns-section'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.9 }}
      >
        <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>Technische Expertise</h2>
        <p>
          Unser Team besteht aus hochqualifizierten Fachleuten, die nicht nur über fundierte Kenntnisse im Projektmanagement verfügen,
          sondern auch technische Expertise in den von uns abgedeckten Bereichen. Wir nutzen modernste Technologien und Methoden,
          um die Effizienz und Qualität unserer Projekte zu maximieren.
        </p>
      </motion.div>

      <motion.div
        className='uber-uns-section'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>Marktposition</h2>
        <p>
          Durch unsere langjährige Erfahrung und unser Engagement haben wir uns als vertrauenswürdiger Partner in der Branche etabliert.
          Wir sind stolz darauf, zahlreiche erfolgreiche Projekte realisiert und unseren Kunden maßgeschneiderte Lösungen angeboten zu haben.
        </p>
      </motion.div>

      <motion.div
        className='uber-uns-section'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.1 }}
      >
        <h2 style={{ textAlign: 'center', marginTop: '2rem' }}>Kontakt</h2>
        <p>
          Für weitere Informationen oder eine persönliche Beratung stehen wir Ihnen jederzeit zur Verfügung.
        </p>
      </motion.div>
    </motion.div>
  );
}

export default UberUns;
