import React, { useEffect, useRef } from 'react';
import './Kontakt.css'; // Ensure your animation styles are here
import { motion } from 'framer-motion';

function Kontakt() {
    const companyRefs = useRef([]);

    useEffect(() => {
        // Capture a stable reference to the current companyRefs when the effect runs
        const currentRefs = companyRefs.current;
    
        // Create an IntersectionObserver
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                } else {
                    entry.target.classList.remove('animate');
                }
            });
        });
    
        // Observe each element
        currentRefs.forEach((el) => {
            if (el) observer.observe(el);
        });
    
        // Cleanup function to unobserve elements
        return () => {
            currentRefs.forEach((el) => {
                if (el) observer.unobserve(el);
            });
        };
    }, []); // Only run this effect once, when the component mounts
    
    

    return (
        <div className="kontakt-container">
            <motion.h1
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                KONTAKT
            </motion.h1>
            <div className="kontakt-map-container">
                <motion.div
                    className="map-container"
                    ref={(el) => (companyRefs.current[1] = el)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3, duration: 0.8 }}
                >
                    <iframe
                        title="Google Map Location"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224525.5059055939!2d10.068778084642045!3d53.55425149060757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b1f16309b04e59%3A0x5b015a2cc5a69f6f!2sAm%20Grossen%20Dahlen%2033%2C%2021077%20Hamburg%2C%20Germany!5e0!3m2!1sen!2sus!4v1633969633455!5m2!1sen!2sus"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    />
                </motion.div>
                <motion.div
                    className="kontakt"
                    ref={(el) => (companyRefs.current[0] = el)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.8 }}
                >
                    <div className="kontakt-location">
                        <h3 className="kontakt-subheading">Unser Standort</h3>
                        <address>
                            MUD Deutschland GmbH <br />
                            Am Grossen Dahlen 33 <br />
                            D-21077 Hamburg, Deutschland
                        </address>
                    </div>
                    <div className="kontakt-info">
                        <p>
                            <i className="fas fa-phone"></i>
                            +49 175 487 50 51
                        </p>
                        <p>
                            <i className="fas fa-phone"></i>
                            +386 41 374 110
                        </p>
                        <p>
                            <i className="fas fa-envelope"></i>
                            wolf<span>(at)</span>mud-deutschland.de
                        </p>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default Kontakt;
