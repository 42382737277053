import '../../App.css';
import Cards from '../Cards';
import MetalSection from '../MetalSection';
import React from 'react';
import UberUns from '../UberUns';
import Footer from '../Footer';

function Home() {
    return (
        <>
        <MetalSection />
        <UberUns />
        <Cards />
        <Footer />
        </>
    );
}

export default Home;