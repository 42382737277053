import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import { motion } from 'framer-motion';
import './Produkte.css';
import { useNavigate } from 'react-router-dom';

function Produkte() {
  const navigate = useNavigate();

    const handleKontaktClick = () => {
        navigate('/kontakt');
    };
  return (
      <>
          <div className="produkte-container">
              <motion.h1
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  className="produkte-title"
              >
                  Produkte
              </motion.h1>
              <motion.h2
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1 }}
                  className="produkte-title"
              >
                  Maßgeschneiderte Lösungen für Ihre Einbausituation
              </motion.h2>
              <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  className="produkte-content"
              >
                  <p>
                      Willkommen auf unserer Website! Wir freuen uns, Ihnen eine breite Palette von Produkten anzubieten, die sich ideal für unterschiedlichste Einbausituationen eignen. Da jede Installation einzigartig ist, bieten wir individuelle Lösungen, die genau auf Ihre Bedürfnisse abgestimmt sind.
                  </p>
                  <p>
                      Egal, ob es sich um Wohnräume, gewerbliche Anwendungen oder spezielle Projekte handelt – unser Team steht bereit, um gemeinsam mit Ihnen die optimale Lösung zu finden. Wir legen großen Wert darauf, dass jedes Produkt perfekt auf Ihre Anforderungen zugeschnitten ist.
                  </p>
                  <p>
                      Bitte zögern Sie nicht, uns für eine maßgeschneiderte Beratung zu kontaktieren. Gemeinsam entwickeln wir die ideale Lösung für Ihr Projekt!
                  </p>
                  <button className="kontakt-button" onClick={handleKontaktClick}>
                        Kontaktieren Sie uns
                  </button>
              </motion.div>
          </div>
          <Footer />
      </>
  );
}

export default Produkte;