import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Navbar.css';

function Navbar() {
    const [click, setClick] = React.useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <>
        <nav className="navbar">
            <div className="navbar-container">
            {/* Wrap img with Link to make it a clickable logo */}
            <Link to="/" className='navbar-logo' onClick={closeMobileMenu}>
                <img src="/logo.png" alt="logo" className="navbar-logo" />
            </Link>
            <div className="menu-icon" onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                    <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                        Home
                    </Link>
                    </li>
                    <li className="nav-item" onClick={closeMobileMenu}>
                    <Link to="/produkte" className="nav-links">
                        Produkte
                    </Link>
                    </li>
                    <li className="nav-item" onClick={closeMobileMenu}>
                    <Link to="/referenzen" className="nav-links">
                        Referenzen
                    </Link>
                    </li>
                    <li className="nav-item" onClick={closeMobileMenu}>
                    <Link to="/kontakt" className="nav-links">
                        Kontakt
                    </Link>
                    </li>
                    <li className="nav-item" onClick={closeMobileMenu}>
                    <Link to="/impressum" className="nav-links">
                        Impressum
                    </Link>
                    </li>
                </ul>
            </div>
        </nav>
        </>
    );
}

export default Navbar;