import React from 'react';
import './Referenzen.css'; // Import the CSS styles for the page
import { motion } from 'framer-motion';
import Footer from '../Footer';

function Referenzen() {
    const references = [
        {
            imgSrc: '/referenzen/urban1.jpg',
            label: 'Urban Village'
        },
        {
            imgSrc: '/referenzen/urban2.jpeg',
            label: 'Urban Village'
        },
        {
            imgSrc: '/referenzen/urban3.jpg',
            label: 'Urban Village'
        },
        {
            imgSrc: '/referenzen/muhl1.jpg',
            label: 'Mühlengrund'
        },
        {
            imgSrc: '/referenzen/muhl2.jpeg',
            label: 'Mühlengrund'
        },
        {
            imgSrc: '/referenzen/muhl3.jpg',
            label: 'Mühlengrund'
        },
        {
            imgSrc: '/referenzen/sch1.jpg',
            label: 'BV Schkeuditz'
        },
        {
            imgSrc: '/referenzen/sch2.jpeg',
            label: 'BV Schkeuditz'
        },
        {
            imgSrc: '/referenzen/sch3.jpeg',
            label: 'BV Schkeuditz'
        },
        {
            imgSrc: '/referenzen/karl1.jpg',
            label: 'BV Karl-Holz Strasse'
        },
        {
            imgSrc: '/referenzen/karl2.jpeg',
            label: 'BV Karl-Holz Strasse'
        },
        {
            imgSrc: '/referenzen/karl3.jpg',
            label: 'BV Karl-Holz Strasse'
        },
        {
            imgSrc: '/referenzen/daum1.jpg',
            label: 'BV Daumstrasse'
        },
        {
            imgSrc: '/referenzen/daum2.jpg',
            label: 'BV Daumstrasse'
        },
        {
            imgSrc: '/referenzen/schke.jpeg',
            label: 'Schkeuditz'
        },
        {
            imgSrc: '/referenzen/havel1.jpeg',
            label: 'BV Haveländer Ring'
        },
        {
            imgSrc: '/referenzen/havel2.jpg',
            label: 'BV Haveländer Ring'
        },
        {
            imgSrc: '/referenzen/havel3.jpg',
            label: 'BV Haveländer Ring'
        },
        {
            imgSrc: '/referenzen/sach1.jpg',
            label: 'Sachsendamm'
        },
        {
            imgSrc: '/referenzen/sach2.jpg',
            label: 'Sachsendamm'
        },
        {
            imgSrc: '/referenzen/ring.jpg',
            label: 'BV Haveländer Ring'
        },
        {
            imgSrc: '/referenzen/paul.jpg',
            label: 'Paul Stern Straße'
        },
        {
            imgSrc: '/referenzen/tanger.jpeg',
            label: 'Tangermünder Strasse'
        },
        {
            imgSrc: '/referenzen/merten.jpg',
            label: 'Mertenstraße'
        },
        {
            imgSrc: '/referenzen/glassgelender1.JPG',
            label: 'Glasgeländer'
        },
        {
            imgSrc: '/referenzen/glassgelender2.PNG',
            label: 'Glasgeländer'
        },
        {
            imgSrc: '/referenzen/glassgelender3.jpeg',
            label: 'Glasgeländer'
        },
    ];

    return (
        <>
            <div className="referenzen-container">
                <motion.h1
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="referenzen-title"
                >
                    REFERENZEN
                </motion.h1>
                <div className="referenzen-gallery">
                    {references.map((reference, index) => (
                        <motion.div
                            key={reference.imgSrc}
                            className="referenzen-item"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}
                        >
                            <div className="image-container">
                                <img src={reference.imgSrc} alt={reference.label} className="gallery-img" />
                                <div className="overlay">
                                    <h3>{reference.label}</h3>
                                </div>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Referenzen;
