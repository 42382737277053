import './App.css';
import Navbar from './compenents/Navbar';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Home from './compenents/pages/Home';
import Produkte from './compenents/pages/Produkte';
import Referenzen from './compenents/pages/Referenzen';
import Kontakt from './compenents/pages/Kontakt';
import Impressum from './compenents/pages/Impressum';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact Component={Home}/>
        <Route path='/produkte' exact Component={Produkte}/>
        <Route path='/referenzen' exact Component={Referenzen}/>
        <Route path='/kontakt' exact Component={Kontakt}/>
        <Route path='/impressum' exact Component={Impressum}/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
