import React, { useState, useEffect } from 'react';
import './MetalSection.css';
import { Button } from './button';
import { motion } from 'framer-motion';
import { useMemo } from 'react';

function MetalSection() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = useMemo(() => [
    '/img1.jpg',
    '/img2.jpeg',
    '/img3.jpeg',
], []); // Static array to avoid unnecessary changes

useEffect(() => {
    const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
}, [images.length]); // This is now safer since images is memoized

  return (
    <motion.div
      className='metal-container'
      initial={{ opacity: 0, y: 50 }} // Animation starting values
      animate={{ opacity: 1, y: 0 }}  // Animation final values
      transition={{ duration: 1 }}     // Duration of the animation
    >
      <h1>Metall und Design</h1>
      <p>Wenn Sie nach hochwertigen Metall- und Designprodukten suchen, sind Sie bei uns genau richtig.</p>
      <div className='metal-btns'>
        <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
          Kontaktieren Sie uns
        </Button>
      </div>
      <div className="image-slider">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className={`slider-image ${index === currentImageIndex ? 'active' : ''}`}
          />
        ))}
      </div>
    </motion.div>
  );
}

export default MetalSection;
